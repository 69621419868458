import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
   Button,
   MaskedInput,
   TableCell,
   TableRow
} from 'grommet'
import { useWaitCommand } from 'hooks/useWaitCommand'
import { ControlFabric } from 'components/Library/ControlFabric/ControlFabric'
import { Edit, MailOption, Phone, Save, User } from 'grommet-icons'
import { mask } from './data/mask'
import { unifi } from './data/unifi'

export const SettingRow = ({
   editable,
   setting,
   setSetting,
   watchdog,
   ...prop
} : {
   editable: boolean,
   setting: string,
   setSetting?: (v: string ) => void,
   watchdog?: boolean,
   alias: string,
   label: string,
   type?: "number" | "datetime-local" | "email" | 'phone' | 'name' | 'password',
   min? : number,
   max? : number,
   sign?: string,
}) => {

   const [value, setValue] = useState (setting)
   const [edit , setEdit ] = useState (false)
   const inputRef = useRef (null)
   const boxRef   = useRef (null)

   const { controlCommand, wait } = useWaitCommand (setSetting)
   const saveSetting = useCallback (() => {

      watchdog
      ? controlCommand ('', 0, unifi (value, prop.type))
      : setSetting (unifi (value, prop.type))

      setEdit (false)
   }, [watchdog, value])


   const icons = useMemo (() => ({
      email: <MailOption />,
      phone: <Phone />,
      name : <User />,
   }), [])

   useEffect(() => {

      const closeClick = e => {

         if (edit &&
               e.target !== inputRef.current &&
               e.target !== boxRef.current   &&
               e.target.tagName != 'path'    &&
               e.target.tagName != 'svg') {

            setEdit (false)
            document.removeEventListener ("click", closeClick)
         }
      }

      const closeBtn = e => {

         if (e.key === 'Escape') {
            setEdit (false)
            document.removeEventListener ("click", closeClick)
         }
         if (e.key === 'Enter') {
            saveSetting ()
            document.removeEventListener ("click", closeClick)
         }
      }

      if (edit) {
         document.addEventListener ("keydown", closeBtn)
         document.addEventListener ("click"  , closeClick)
      }

      return () => {
         document.removeEventListener ("keydown", closeBtn)
         document.removeEventListener ("click"  , closeClick)
      }
   }, [edit, value])

   return (
      <TableRow>
         <TableCell
            scope = "row"
            size  = 'xsmall'
            border = { {
               side: 'right',
               size: 'small'
            } }
         >
            <strong>{ prop.label }</strong>
         </TableCell>

         <TableCell
            border = { {
               side: 'right',
               size: 'small'
            } }
         >
            { editable && edit
               ? (
                  <MaskedInput
                     { ...prop }
                     value = { value }
                     icon  = { icons[prop.type] }
                     mask  = { mask[prop.type] }
                     ref   = { inputRef }
                     onChange = { ({ target }) => setValue (target.value) }
                  />
               ) : (
                  value
               )
            }
         </TableCell>

         <TableCell ref = { boxRef } >
            { editable &&
               <>
                  { edit
                     ? (
                        <Button
                           secondary
                           fill    = { false }
                           icon    = { <Save color = 'backgroundIcon'/> }
                           pad     = '0'
                           onClick = { saveSetting }
                        />
                     ) : (
                        <ControlFabric wait = { wait }>
                           <Button
                              secondary
                              fill    = { false }
                              icon    = { <Edit color = 'backgroundIcon'/> }
                              pad     = '0'
                              onClick = { () => setEdit (true) }
                           />
                        </ControlFabric>
                     )
                  }
               </>
            }
         </TableCell>
      </TableRow>
   )
}