import React, { useCallback, useContext, useState } from 'react'
import { observer  } from 'mobx-react'
import { useParams } from 'react-router-dom'

import { setControllerSetting } from 'api/controller'
import { controllerStateStore } from 'store/ControllerStateStore'
import { controlPanelStore    } from 'store/ControlPanelStore'
import { SettingsOption       } from 'grommet-icons'

import { TCPSetting } from 'models/ControlPanel'
import { Modal      } from 'components/Library/Modal/Modal'
import { SettingRow } from 'components/Setting/components/SettingRow'
import {
   Box,
   Button,
   ResponsiveContext,
   Table,
   TableBody,
} from 'grommet'

export const Setting = observer (({ setting } : { setting: TCPSetting[] }) => {

   const { idProject } = useParams ()
   const size = useContext (ResponsiveContext)

   const [modal, setModal] = useState (true)
   const [set  , setSet  ] = useState (
      setting.map (set => ({
         id   : set.id,
         value: controllerStateStore.state
         .find (stat => stat.name == set.id)?.value
      }))
   )

   const setRowValue = useCallback ((
      id   : string,
      value: number
   ) => {

      setSet (prev => {

         const set = {
            id: id,
            value: value
         }

         if (prev.some (set => set.id === id)) {

            const idx = prev?.flatMap (set => set.id).indexOf (id)
            prev?.splice (idx, 1, set)
            return prev

         } else return [...prev, set]
      })
   }, [])

   //console.log (setting)

   return (
      <>
         <Button
            { ...(size == 'medium' ? { primary: true } : { secondary: true }) }
            { ...(size == 'medium' ? { label: 'Настройка' } : {} ) }
            icon    = { <SettingsOption /> }
            onClick = { () => setModal (true) }
            pad     = 'xsmall'
         />

         <Modal
            modal    = { modal }
            setModal = { setModal }
            heading  = 'Настройки'
         >
            <Box
               direction = 'column'
               justify   = 'between'
               fill
            >
               <Table>
                  <TableBody>

                     { controlPanelStore.baseSetting.map (prop =>

                        <SettingRow
                           key        = { prop.id }
                           set        = { controllerStateStore.state
                           .find (stat => stat.name === prop.id)?.value ?? '' }
                           setSetting = { setRowValue }
                           watchdog   = { true }
                           { ...prop }
                        />
                     ) }

                  </TableBody>
               </Table>

               <Box
                  direction = "row"
                  justify   = 'between'
               >
                  <Button
                     type    = "reset"
                     label   = "Отменить"
                     onClick = { () => setModal (false) }
                  />
                  <Button
                     primary
                     label   = { 'Сохранить' }
                     onClick = { () => {
                        setControllerSetting (Number (idProject), 'VentController', set)
                        setModal (false)
                     } }
                  />
               </Box>
            </Box>
         </Modal>
      </>
   )
})