import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'

import { controllerStateStore } from 'store/ControllerStateStore'
import { TControllerState     } from 'models/ControllerState'
import { TTagBlock            } from 'models/ControlPanel'

import { Box, Heading, Text   } from 'grommet'
import { Loader               } from 'components/Common/Loader'
import { toJS                 } from 'mobx'

export const IndicationBlock = observer (( { block } : { block: TTagBlock }) => {

   const [stat, setStat] = useState <TControllerState>(controllerStateStore.state
   ?.find (stat => stat.name === block.id))

   useEffect (() => {

      const st = toJS (controllerStateStore.state?.find (stat => stat.name === block.id))

      if (st) {

         if (block?.divisor) st.value =  st.value / block.divisor
         if (block?.format ) st.value = +st.value.toFixed (block.format)
         else                st.value = +st.value.toFixed ()

         setStat (st)
      }
   }, [controllerStateStore.state])

   if (controllerStateStore.loading) return <Loader /> 
   else {
      if (stat != undefined) return (

         <Box
            round      = "small"
            pad        = "xsmall"
            background = "brand"
            flex       = 'grow'
         >
            <Heading
               level     = { 3 }
               textAlign = "center"
               margin    = { { top: 'xsmall', bottom: 'none' } }
               weight    = 'lighter' 
               fill
            >
               { block.label ?? block.id }
            </Heading>

            <Box fill>
               <Text
                  weight    = "bold"
                  textAlign = "center"
                  size      = "2xl"
                  margin    = { { vertical: "xsmall" } }
               >
                  { block.look ? eval (block.look) (stat?.value) : stat?.value } { block?.sign }
               </Text>
            </Box>
         </Box>
      )
      else return <></>
   }
})