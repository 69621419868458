import React from 'react'
import { observer } from 'mobx-react'

import { TControlBlock, TCustomBlock, TTagBlock } from 'models/ControlPanel'
import { TGroup         } from 'models/ControlPanel'

import { useCorrelation } from 'hooks/useCorrelation'
import { useBlocks      } from 'hooks/useBlocks'

import { Box, Heading   } from 'grommet'
import { Setting        } from 'components/Setting/Setting'

export const Group = observer (({ block } : { block: TGroup }) => {

   const {
      style,
      className,
      label
   } = useCorrelation (block)

   if (block?.label != undefined) {

      return (
         <Box 
            direction  = 'column'
            pad        = "medium"
            flex       = 'grow'
            fill       = 'horizontal'
            border     = { { color: 'brand', size: 'xsmall' } }
            style      = { { ...block.style, ...style, gap: '24px' } }
            //@ts-ignore
            background = { block?.style?.background }
            round
         >
            <Box
               direction = 'row'
               justify   = 'between'
               align     = 'center'
               wrap
            >
               { (label || block?.label) &&

                  <Heading
                     level  = { 2 }
                     margin = { { vertical: 'none' } }
                  >
                     { label ?? block?.label }
                  </Heading>

               }

               { block.setting?.length > 0 &&
                  <Setting setting = { block?.setting } />
               }
            </Box>

            <Box 
               direction = 'row'
               flex      = { { grow: 1, shrink: 1 } }
               style     = { { gap: '24px' } }
               wrap
            >
               { block.group?.map ((
                  block:
                  TControlBlock |
                  TCustomBlock  |
                  TTagBlock     |
                  TGroup
               ) => useBlocks (block)) }
            </Box>
         </Box>
      )}
   else {

      return (
         <Box 
            direction  = 'row'
            flex       = { { grow: 1, shrink: 1 } }
            style      = { { ...block.style, ...style, gap: '24px' } }
            fill       = 'horizontal'
            className  = { `${className} ${block.class}` }
            //@ts-ignore
            background = { block?.style?.background }
            round
            wrap
         >
            { block.group?.map ((
               block:
               TControlBlock |
               TCustomBlock  |
               TTagBlock     |
               TGroup
            ) => useBlocks (block)) }
         </Box>
      )
   }
})