import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { controllerStateStore } from 'store/ControllerStateStore'
import { TControl             } from 'models/ControlPanel'

import {
   ResponsiveContext,
   Box,
   Button,
   RangeInput,
   Text
} from 'grommet'
import { ControlFabric        } from "components/Library/ControlFabric/ControlFabric"

import { useWaitCommand       } from "hooks/useWaitCommand"
import { useCorrelation       } from 'hooks/useCorrelation'
import {
   AddCircle,
   SubtractCircle
} from 'grommet-icons'

export const ControlRangeInput = observer (({
   alias,
   control,
   watchdog,
   sendCommand
}: {
   alias      : string,
   control    : TControl,
   watchdog   : boolean,
   sendCommand: (
      a : string,
      c : number,
      v?: number
   ) => void
}) => {

   const state = controllerStateStore.state
   const size = useContext (ResponsiveContext)

   const [rangeValue, setRangeValue] = useState <number> (state
   ?.find (state => state.name == control.id)?.value)
   const [confirm, setConfirm] = useState (false)

   const { controlCommand, wait } = useWaitCommand (sendCommand)

   const {
      style,
      className,
      disabled,
      label
   } = useCorrelation (control)

   useEffect (() => {
      if (!confirm)
         setRangeValue (state
         ?.find (state => state.name == control.id)?.value)
   }, [controllerStateStore?.state, confirm])

   return (
      <Box direction = 'column'>

         { control.options.map (option => (

            <ControlFabric
               key  = { option.command }
               wait = { wait }
            >
               <Box>

                  { rangeValue != undefined &&
                     <Box
                        justify = 'end'
                        fill    = 'horizontal'
                     >
                        <Text alignSelf = 'center'>
                           { option.text
                              ? eval (option.text) (state)
                              : `${label ?? option.label}: ${rangeValue} ${control?.sign ?? ''}` 
                           }
                        </Text>
                     </Box>
                  }

                  <Box
                     direction = 'row'
                     margin    = { { top: 'medium' } }
                     fill      = 'horizontal'
                     gap       = 'medium'
                  >
                     <Button
                        secondary
                        icon    = { <SubtractCircle /> }
                        onClick = { () => {
                           setRangeValue (prev => prev - 1)
                           setConfirm (true)
                        } }
                        pad = '0'
                     />
                     <RangeInput
                        value      = { control.look ? eval (control.look) (rangeValue) : rangeValue }
                        max        = { option.max }
                        min        = { option.min }
                        onChange   = { e => setRangeValue (Number (e.target.value)) }
                        onTouchEnd = { () => setConfirm (true) }
                        onMouseUp  = { () => setConfirm (true) }
                        disabled   = { disabled }
                        style      = { style }
                        className  = { className }
                     />
                     <Button
                        secondary
                        icon    = { <AddCircle /> }
                        onClick = { () => {
                           setRangeValue (prev => prev + 1)
                           setConfirm (true)
                        } }
                        pad = '0'
                     />
                  </Box>

                  { confirm &&
                     <Box
                        direction = "row"
                        justify   = { size == 'small' ? 'between' : 'end' }
                        margin    = { { top: 'medium' } }
                        gap       = 'medium'
                     >
                        <Button
                           primary
                           label   = { 'Сохранить' }
                           onClick = { () => {
                              watchdog
                              ? controlCommand (alias, option.command, rangeValue)
                              : sendCommand    (alias, option.command, rangeValue)
                              setConfirm (false)
                           } }
                        />
                        <Button
                           label   = "Отменить"
                           onClick = { () => {
                              setRangeValue (state
                              ?.find (state => state.name == control.id)?.value)
                              setConfirm (false)
                           } }
                        />
                     </Box>
                  }
               </Box>
            </ControlFabric>
         )) }
      </Box>
   )
})
