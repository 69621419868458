import React, { useEffect, useState } from "react"

import { TCustomBlock    } from "models/ControlPanel"
import { TTarget         } from "models/ControllerState"
import { TSample         } from "models/Sample"
import { TTag            } from "models/Tag"

import { devicesStore    } from "store/DevicesStore"
import { useCorrelation  } from "hooks/useCorrelation"

import { Modal           } from 'components/Library/Modal/Modal'
import { TagChart        } from 'components/Blocks/TagBlock/components/components/TagChart'
import { Time            } from "grommet-icons"
import {
   Box,
   Button,
   CardBody,
   CardFooter,
   CheckBox,
   Text
} from "grommet"
import { sampleStore } from "store/SampleStore"
import { ControlFabric } from "components/Library/ControlFabric/ControlFabric"

export const TargetScheduleEnable = ({
   block,
   targetValue,
   setEdit,
   sendCommand,
   wait
}: {
   block      : TCustomBlock,
   targetValue: TTarget,
   setEdit    : (b: boolean) => void,
   sendCommand: (v?: TTarget) => void,
   wait   : boolean,
}) => {

   const [sample, setSample] = useState <TSample> ()
   const [modal , setModal ] = useState <boolean> ()
   const [tag              ] = useState <TTag> (devicesStore.tags.find (tag => tag.id_tag === Number (block.tag)))

   useEffect (() => { 

      if (tag && sampleStore.samples.length > 0)
         setSample (sampleStore.samples.find (({ id_tag }) => id_tag == tag.id_tag))
   }, [sampleStore.samples])

   const {
      style,
      className,
      disabled
   } = useCorrelation (block.controls
   .find (control => control.options
   .find (option => /Состояние/.test (option.id) && option.state == Number (targetValue?.isenable))), targetValue?.isenable)

   return (

      <>
         <CardBody>

            { targetValue 
               ? (
                  <>
                     <Text
                        textAlign = 'center'
                        size      = "large"
                        margin    = { { vertical: "xsmall" } }
                     >
                        { (targetValue?.time1 != undefined && targetValue?.value1 != undefined) 
                        ? `C ${targetValue?.time1}:00 - ${targetValue?.value1} ${tag?.sign ?? block?.sign}`
                        : 'не установлено' }
                        <br />
                        { (targetValue?.time2 != undefined && targetValue?.value2 != undefined)
                        ? `C ${targetValue?.time2}:00 - ${targetValue?.value2} ${tag?.sign ?? block?.sign}`
                        : 'не установлено' }
                     </Text>

                     { tag &&
                        <Box
                           direction = 'row'
                           justify = 'center'
                        >
                           <Text
                              textAlign = 'center'
                              size      = "large"
                              margin    = { { vertical: "xsmall" } }
                              color     = 'active-text'
                           >
                              { 'Текущее показание: ' + ((devicesStore.tags.some (tag => tag.id_tag === Number (block?.tag)))
                              ? sample?.sample + ' ' + (tag?.sign ?? block?.sign)
                              : 'нет данных') }
                              <Button
                                 primary
                                 icon    = { <Time /> }
                                 margin  = { { horizontal: '10px' } }
                                 onClick = { () => setModal (true) }
                              />
                           </Text>
                        </Box>
                     }
                  </>
               ) : (
                  <Text
                     textAlign = 'center'
                     size      = "large"
                     margin    = { { vertical: "medium" } }
                  >
                     Расписание не задано
                  </Text>
               )
            }

         </CardBody>

         <CardFooter
            justify = 'between'
            margin  = { { top: "small" } }
         >
            <ControlFabric
               wait = { wait }
            >
               <CheckBox
                  checked   = { targetValue?.isenable }
                  onChange  = { e =>  sendCommand ({ ...targetValue, isenable: e.target.checked }) }
                  disabled  = { disabled }
                  style     = { style }
                  className = { className }
                  label     = { block.controls
                  .flatMap (control => control.options)
                  .find    (option => /Состояние/.test (option.id) && option.state == Number (targetValue?.isenable))?.label ?? 'Авто' }
                  toggle
               />
            </ControlFabric>

            <ControlFabric
               wait = { wait }
            >
               <Button
                  primary
                  reverse
                  label   = "Изменить"
                  onClick = { () => setEdit (true) }
               />
            </ControlFabric>

         </CardFooter>

         { devicesStore.tags
         .some (tag => tag.id_tag === Number (block?.tag)) &&

            <Modal
               modal    = { modal }
               setModal = { setModal }
               heading  = { 'Показания параметра "' + tag?.name + '"' }
            >
               <TagChart
                  tag   = { tag }
                  block = { block }
               />
            </Modal>
         }
      </>
   )
}