import React, { useContext } from 'react'
import { observer     } from 'mobx-react'
import { useParams    } from 'react-router-dom'
import { projectStore } from 'store/ProjectsStore'

import {
   PageHeader,
   ResponsiveContext
} from 'grommet'

export const Greeting = observer (() => {

   const { idProject, idPanel } = useParams ()
   const size = useContext (ResponsiveContext)

   return (
      <PageHeader
         title  = { projectStore.projects.find (project => project.id_project === Number (idProject) &&
                                                           project.id_controlpanel === Number (idPanel))?.cpname }
         margin = { size == 'small' ? { bottom: '0' } : { bottom: 'medium' } }
         pad    = { { top: "small" } }
         fill   = 'horizontal'
         responsive
      />
   )
})