import React from "react"
import { observer } from "mobx-react"

import { devicesStore         } from 'store/DevicesStore'
import { controlPanelStore    } from "store/ControlPanelStore"
import { controllerStateStore } from "store/ControllerStateStore"

import { Box, Heading         } from "grommet"
import { IndicationBlock      } from "./components/IndicationBlock"
import { TagBlock             } from "./components/TagBlock"
import { DummyTagBlock        } from "./components/DummyTagBlock"

export const TagBlocks = observer (() => {

   if (controllerStateStore.state?.length > 0 &&
         (controlPanelStore.tagBlocks?.length > 0 ||
          controlPanelStore.indicationBlocks?.length > 0)
   ) {

      return (

         <Box
            border = { { color: 'brand', size: 'xsmall' } }
            style  = { { gap: '24px' } }
            margin = { { bottom: "medium" } }
            pad    = 'medium'
            round
         >
            <Heading
               level  = { 3 }
               margin = { { vertical: 'none' } }
            >
               Текущие показатели
            </Heading>

            { (devicesStore.tags?.length > 0            &&
               controlPanelStore.tagBlocks?.length > 0) &&
                  <Box
                     direction = 'row'
                     flex      = { { grow: 1, shrink: 1 } }
                     style     = { { gap: '24px' } }
                     wrap
                  >

                     { controlPanelStore.tagBlocks.map (block => 
                        <TagBlock
                           key   = { block.id }
                           block = { block }
                        />
                     ) }
                     { (controlPanelStore.dummyTagBlocks?.length > 0  &&
                        controlPanelStore.dummyTagBlocks?.map (block => 
                           <DummyTagBlock
                              key   = { block.id }
                              block = { block }
                           />
                        )
                     ) }
                  </Box>
            }

            { (controlPanelStore.indicationBlocks?.length > 0  &&
               controlPanelStore.indicationBlocks?.find (block =>
                  controllerStateStore.state.find (stat => stat.name == block.id)
               )) &&

                  <Box
                     direction = 'row'
                     flex      = { { grow: 1, shrink: 1 } }
                     style     = { { gap: '24px' } }
                     wrap
                  >
                     { controlPanelStore.indicationBlocks.map (block => 
                        <IndicationBlock
                           key   = { block.id }
                           block = { block }
                        />
                     ) }
                     { (controlPanelStore.dummyTagBlocks?.length > 0  &&
                        controlPanelStore.dummyTagBlocks?.map (block => 
                           <DummyTagBlock
                              key   = { block.id }
                              block = { block }
                           />
                        )
                     ) }
                  </Box>
            }

            

         </Box>
      )}
})