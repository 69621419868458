export const mask = {
   number: [
      {
         length: [1, 2],
         regexp: /^[0-9]{1,2}$/,
         placeholder: '00'
      }
   ],
   email: [
      {
         regexp: /^[\w\-_.]+$/,
         placeholder: 'example',
      },
      { fixed: '@' },
      {
         regexp: /^[\w]+$/,
         placeholder: 'my',
      },
      { fixed: '.' },
      {
         regexp: /^[\w]+$/,
         placeholder: 'com',
      },
   ],
   phone: [
      { fixed: '+' },
      {
         length: 1,
         regexp: /^[0-9]{1}$/,
         placeholder: '7',
      },
      { fixed: ' ' },
      { fixed: '(' },
      {
         length: 3,
         regexp: /^[0-9]{1,3}$/,
         placeholder: '900',
      },
      { fixed: ')' },
      { fixed: ' ' },
      {
         length: 3,
         regexp: /^[0-9]{1,3}$/,
         placeholder: '000',
      },
      { fixed: '-' },
      {
         length: 4,
         regexp: /^[0-9]{1,4}$/,
         placeholder: '0000',
      },
   ]
}