import { api } from "api/client"
import { TChartRequest } from "models/Chart"
import { TChartSample  } from "models/Sample"

export const getTagChart = async (
   data: {
      tags      : TChartRequest[],
      dBegin    : string | string[],
      dEnd      : string | string[],
      fullData? : boolean,
      groupData?: boolean,
   }
): Promise<TChartSample[]> => {
   return await api.send ('POST', '/tag/chart/', data)
}