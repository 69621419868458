import { makeAutoObservable } from 'mobx'

import {
   TGroup,
   TControlBlock,
   TCustomBlock,
   TTagBlock,
   TCPSetting
} from 'models/ControlPanel'

import { projectStore } from './ProjectsStore'
import { flatAll      } from 'utils/flatAll'
// import { ventilationCP } from 'data/schemes/ventilationCP'
// import { heatingCP } from 'data/schemes/heatingCP'
// import { ventstationCP } from 'data/schemes/ventstationCP'

export class ControlPanelStore {

   aliases         : string[] = []
   groups          : TGroup[] = []
   baseSetting     : TCPSetting[] = []
   controlBlocks   : TControlBlock[] = []
   targetBlocks    : TCustomBlock[] = []
   targetSchedules : TCustomBlock[] = []
   riftSchedules   : TCustomBlock[] = []
   tagBlocks       : TTagBlock[] = []
   indicationBlocks: TTagBlock[] = []
   dummyTagBlocks  : TTagBlock[] = []
   tagControlBlocks: TTagBlock[] = []
   sheduleOptions = []
   loading        = false

   constructor () { makeAutoObservable (this) }

   getState (idProject, idControlPanel) {

      this.loading  = true

      if (projectStore?.projects?.length > 0) {

         const config = projectStore?.projects
         .find (project => project.id_project === idProject &&
                project.id_controlpanel == idControlPanel)?.config

         let projectConfig //= ventstationCP

         if (typeof config === 'string') projectConfig = JSON.parse (config)

         if (projectConfig) {

            //@ts-ignore
            this.aliases          = [...new Set (
               flatAll (projectConfig.outputs)?.map (block => block.alias).filter (alias => alias != undefined).concat (
                  flatAll (projectConfig.inputs )?.map (block => block.alias).filter (alias => alias != undefined))
            )]
            this.tagBlocks        = projectConfig.inputs?.filter  (blocks => blocks.type == 'TagBlock')
            this.dummyTagBlocks   = projectConfig.inputs?.filter  (blocks => blocks.type == 'DummyTagBlock' )

            //@ts-ignore
            this.groups           = projectConfig.outputs?.filter (blocks => blocks.type == 'Group' )
            this.controlBlocks    = flatAll (projectConfig.outputs)?.filter (blocks => blocks.type == 'ControlBlock')
            this.tagControlBlocks = flatAll (projectConfig.outputs)?.filter (blocks => blocks.type == 'TagBlock')
            this.targetBlocks     = flatAll (projectConfig.outputs)?.filter (blocks => blocks.type == 'TargetBlock' )
            this.sheduleOptions   = flatAll (projectConfig.outputs)?.filter (blocks => blocks.type == 'ControlBlock')
            this.targetSchedules  = flatAll (projectConfig.outputs)?.filter (blocks => blocks.type == 'TargetSchedule')
            this.riftSchedules    = flatAll (projectConfig.outputs)?.filter (blocks => blocks.type == 'RiftSchedule')
            this.baseSetting      = projectConfig.setting
         }
      }

      this.loading = false
   }

   getControlBlockByCommand (command: number) {

      return this.controlBlocks
      .find    (controlBlock => controlBlock.controls
      .flatMap (control => control.options)
      .find    (option => option.command == command))
   }

   getIndicationBlockByCommand (command: number) {

      return this.targetBlocks
      .find    (indicationBlock => indicationBlock.controls
      .flatMap (control => control.options)
      .find    (option => option.command == command))
   }

   getTargetSchedulesByCommand (command: number) {

      return this.targetSchedules
      .flatMap (block => block.controls)
      .find    (control => control.options
      .find    (option => option.command == command))
   }

   getControlByCommand (command: number) {

      return this.controlBlocks
      .flatMap (controlBlock => controlBlock.controls)
      .find    (control => control.options
      .find    (option => option.command == command))
   }

   getIndicationByCommand (command: number) {

      return this.targetBlocks
      .flatMap (indicationBlock => indicationBlock.controls)
      .find    (control => control.options
      .find    (option => option.command == command))
   }

   getTargetSchedulesOptionByCommand (command: number) {

      return this.targetSchedules
      .flatMap (controlBlock => controlBlock.controls)
      .flatMap (control => control.options)
      .find    (option => option.command == command)
   }

   getControlOptionByCommand (command: number) {

      return this.controlBlocks
      .flatMap (controlBlock => controlBlock.controls)
      .flatMap (control => control.options)
      .find    (option => option.command == command)
   }

   getIndicationOptionByCommand (command: number) {

      return this.targetBlocks
      .flatMap (indicationBlock => indicationBlock.controls)
      .flatMap (control => control.options)
      .find    (option => option.command == command)
   }
}

export const controlPanelStore = new ControlPanelStore ()