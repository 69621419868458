import React, { useContext } from 'react'
import { Refresh, Time     } from 'grommet-icons'
import { intervalOptions   } from 'data/common/chartInterval'

import { ChartContext } from '../TagChart'
import { TTag         } from 'models/Tag'

import {
   Box,
   Button,
   CheckBox,
   DateInput,
   ResponsiveContext,
   Select,
   Tip
} from 'grommet'
import { TCustomBlock, TTagBlock } from 'models/ControlPanel'

export const ChartControls = ({
   tag,
   block,
   fetchChartData
} : {
   tag: TTag,
   block: TCustomBlock | TTagBlock,
   fetchChartData: () => void
}) => {

   const size = useContext (ResponsiveContext)
   const {
      dBegin   , setDBegin,
      dEnd     , setDEnd,
      interval , setInterval,
      fullData , setFullData,
      groupData, setGroupData,
   } = useContext (ChartContext)

   return (
      <Box flex = 'shrink'>

         { (tag?.ngroup && !block?.charts) &&
            <Box margin = { { bottom: 'medium' } } >
               <CheckBox
                  label    = { size != 'small' 
                               ? <span style = { { fontSize: 'larger' } }>{ 'Отобразить данные группы параметров' }</span>
                               : 'Группа параметров' }
                  checked  = { groupData }
                  onChange = { e => setGroupData (e.target.checked) }
                  toggle
               />
            </Box>
         }

         <Box
            direction = 'row'
            gap       = 'medium'
         >
            <Button 
               icon    = { <Refresh /> }
               onClick = { fetchChartData }
               primary
            />

            <Box fill = 'horizontal'>
               <Select
                  options  = { intervalOptions }
                  value    = { interval }
                  onChange = { ({ option }) => setInterval (option.id) }
                  labelKey = 'label'
                  valueKey = 'id'
               />
            </Box>

            {  interval == 1 &&
               <Tip content = 'Получить подробные данные'>
                  <Box
                     direction = 'row'
                     align     = 'center'
                     gap       = 'small'
                     flex      = 'grow'
                     style     = { { borderRadius: '12px' } }
                     pad       = { { horizontal: 'small' } }
                     border
                  >
                     <Time />
                     <CheckBox
                        checked  = { fullData }
                        onChange = { event => setFullData (event.target.checked) }
                     />
                  </Box>
               </Tip> 
            }
         </Box>

         { interval == 4 &&
            <Box
               direction = 'row'
               gap       = 'small'
               margin    = { { vertical: 'small' } }
            >
               <DateInput
                  format        = "dd.mm.yyyy"
                  value         = { dBegin }
                  onChange      = { ({ value }) => {setDBegin (value)} }
                  calendarProps = { {
                     locale: "ru-RU",
                     firstDayOfWeek: 1
                  } }
               />
               <DateInput
                  format        = "dd.mm.yyyy"
                  value         = { dEnd }
                  onChange      = { ({ value }) => {setDEnd (value)} }
                  calendarProps = { {
                     locale: "ru-RU",
                     firstDayOfWeek: 1
                  } }
               />
            </Box>
         }
      </Box>
   )
}