import React, { useState, useEffect, useMemo } from 'react'
import { observer             } from 'mobx-react'

import { TSample              } from 'models/Sample'
import { TTag                 } from 'models/Tag'
import { TTagBlock            } from 'models/ControlPanel'
import { TControllerState     } from 'models/ControllerState'

import { controllerStateStore } from 'store/ControllerStateStore'
import { devicesStore         } from 'store/DevicesStore'
import { sampleStore          } from 'store/SampleStore'

import { CircleAlert          } from 'grommet-icons'

import { Loader               } from 'components/Common/Loader'
import { Modal                } from 'components/Library/Modal/Modal'
import { TagChart             } from './components/TagChart'
import { Box, Heading, Text   } from 'grommet'

import { dayTitle             } from 'utils/dayTitle'

export const TagBlock = observer (( { block } : { block: TTagBlock }) => {

   const tag: TTag = useMemo (() => devicesStore.tags
   .find (tag => tag.id == Number (block?.tag ?? block.id) && tag.alias == block.alias),
   [block])

   const [modal , setModal ] = useState <boolean> ()
   const [sample, setSample] = useState <TSample> ()
   const [stat  , setStat  ] = useState <TControllerState> ()

   useEffect (() => { 
      if (tag && sampleStore.samples.length > 0)
         setSample (sampleStore.samples?.find (({ id_tag }) => id_tag == tag.id_tag))
   }, [sampleStore.samples])

   useEffect (() => {
   
      const st = controllerStateStore.state?.find (stat => stat.name === (block?.state ?? block.id))

      if (st) {

         if (block?.divisor) st.value =  st.value / block.divisor
         if (block?.format ) st.value = +st.value.toFixed (block.format)
         else                st.value = +st.value.toFixed ()

         setStat (st)
      }
   }, [controllerStateStore.state])

   const difTime = Date.now () - new Date (sample?.dsample).getTime ()

   return (

      <Box
         round      = "small"
         pad        = "small"
         background = "brand"
         flex       = 'grow'
         onClick    = { sample ? () => setModal (true) : undefined }
      >
         <Heading
            level     = { 3 }
            textAlign = "center"
            margin    = { { top: 'xsmall', bottom: 'none' } }
            weight    = 'lighter' 
            fill
         >
            { block?.label ?? tag?.name }
         </Heading>

         { controllerStateStore.state?.length > 0
            ? (sample || stat)
               ? (
                  <Box fill>
                     { stat
                        ? (
                           <Text
                              weight    = "bold"
                              textAlign = "center"
                              size      = "2xl"
                              margin    = { { vertical: "xsmall" } }
                           >
                              { block?.look ? eval (block?.look) (stat?.value) : stat?.value } { block?.sign ?? tag?.sign }
                           </Text>
                        ) : (
                           <>
                              { sample?.error_text
                                 ? (
                                    <Text
                                       textAlign = "center"
                                       margin    = { { vertical: "small" } }
                                    >
                                       { sample.error_text }
                                    </Text>
                                 ) : (
                                    <>
                                       <Text
                                          weight    = "bold"
                                          textAlign = "center"
                                          size      = "2xl"
                                          margin    = { { vertical: "xsmall" } }
                                       >
                                          { block?.look ? eval (block?.look) (sample?.sample) : sample?.sample }  { block?.sign ?? tag?.sign }
                                       </Text>

                                       { difTime > 600000 &&
                                          <Box
                                             direction = 'row'
                                             align     = 'center'
                                             justify   = 'center'
                                             gap       = 'small'
                                          >
                                             <CircleAlert />
                                             <Text
                                                size      = "xsmall"
                                                margin    = { { vertical: "xsmall" } }
                                             >
                                                { 'Показания устарели' }<br />{ 'Прошло: ' }
                                                {
                                                   difTime > 86400000
                                                   ? `${(difTime / 86400000).toFixed ()} ${dayTitle (Number ((difTime / 86400000).toFixed ()))}`
                                                   : new Date (difTime).toLocaleTimeString ('ru-RU', { timeZone: 'UTC' })
                                                }
                                             </Text>
                                          </Box>
                                       }
                                    </>
                                 )
                              }
                           </>
                        )
                     }
                  </Box>
               ) : (
                  <Box 
                     align  = "center"
                     margin = { { vertical: "small" } }
                  >
                     Показания не найдены
                  </Box>
               )
            : controllerStateStore.loading 
               ? <Loader size = 'medium' />
               : (
                  <Box 
                     align  = "center"
                     margin = { { vertical: "small" } }
                  >
                     Контроллер не подключен
                  </Box>
               )
         }

         { sample &&
            <Modal
               modal    = { modal }
               setModal = { setModal }
               heading  = { 'Показания параметра "' + (block?.label ?? tag?.name) + '"' }
            >
               <TagChart 
                  tag   = { tag }
                  block = { block }
               />
            </Modal>
         }
      </Box>
   )
})