import { useCallback, useState } from 'react'
import { getTagChart        } from 'api/tagchart'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'

import { TTag               } from 'models/Tag'
import { TChartRequest      } from 'models/Chart'
import { TCustomBlock, TTagBlock } from 'models/ControlPanel'

import { devicesStore   } from 'store/DevicesStore'
import { ChartProcessor } from './chartProcessor'

import {
   ChartData,
   Point,
   ScaleOptionsByType,
   CartesianScaleTypeRegistry,
} from 'chart.js'

export const useChartData = (
   tag      : TTag,
   block    : TTagBlock | TCustomBlock,
   interval : number,
   dBegin   : string,
   dEnd     : string,
   fullData : boolean,
   groupData: boolean,
) => {

   const [loading, setLoading] = useState (true)
   const [options, setOptions] = useState <{
      labels  : ChartData['labels'],
      datasets: ChartData <'line', (number | Point)[]> ['datasets'],
      scales  : _DeepPartialObject<{ [key: string]: ScaleOptionsByType<keyof CartesianScaleTypeRegistry> }>
   }> ()

   const fetchChartData = useCallback (() => {

      setLoading (true)
      let tags : TChartRequest[] = [{ id_tag: tag?.id_tag }]

      if (block?.charts) {

         tags = block?.charts
         .flatMap(chart => {

            const getTags = (id: number) => devicesStore.tags
            .find(tag => tag.id === id && tag.alias === block.alias)?.id_tag

            if (chart.type === 'diff')
               return { id: chart.tags.map (id => getTags (id)), reduce: chart.reduce }

            if (chart.type == 'tag')
               return { id_tag: getTags (chart.id), reduce: chart.reduce }

            if (chart.type == 'rift')
               return { id_tag: getTags (chart.id), type: chart.type }

            return []
         })

      }

      getTagChart ({
         tags,
         dBegin,
         dEnd,
         fullData,
         groupData
      })
      .then (samples => {

         const chartProcessor = new ChartProcessor (tag, block, samples, interval, fullData)
         setOptions (chartProcessor.processChartData ())
      })
      .finally (() => setLoading (false))

   }, [tag, dBegin, dEnd, fullData, groupData])

   return { options, loading, fetchChartData }
}
